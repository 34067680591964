import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#9F63FF",
    },
    success: {
      main: "#10d915",
    },
    error: {
      main: "#f27474",
    },
    warning: {
      main: "#d9c404",
    },
    secondary: {
      main: "#0f85d9",
    },
  },
});

export default Theme;