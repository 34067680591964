import React, { useState, useEffect } from 'react';
import { Container, Grid, IconButton, Tooltip, Typography, Box, ListItem, List, Divider, Button, TextField, Paper } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import HelpIcon from '@mui/icons-material/Help';
import StorageIcon from '@mui/icons-material/Storage';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NotesIcon from '@mui/icons-material/Notes';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const options = [
    {
        icon: <NoteAddIcon />,
        title: 'Prepare Note',
        description: 'Create and save your own notes in the workspace. Organize your thoughts and ideas to stay on top of tasks and projects.',
        color: '#9F63FF'
    },
    {
        icon: <HelpIcon />,
        title: 'Questions',
        description: 'Store your questions and answers for future reference. Easily revisit and review them whenever you need clarity.',
        color: '#f27474'
    },
    {
        icon: <StorageIcon />,
        title: 'CheatSheet',
        description: 'Create cheat sheets to help you with your work. Quickly reference important tips, shortcuts, or steps for your tasks.',
        color: '#d9c404'
    },
    {
        icon: <UploadFileIcon />,
        title: 'Upload File',
        description: 'Upload and save files in your workspace. Keep important documents, images, and media all in one place for easy access.',
        color: '#0f85d9'
    },
    {
        icon: <PlaylistAddCheckIcon />,
        title: 'Todo List',
        description: 'Keep track of your tasks with a personalized todo list. Stay organized and prioritize your tasks to improve productivity.',
        color: '#12de93'
    },
];

const WorkspacePage = () => {
    const [openOptions, setOpenOptions] = useState(false);
    const [visibleIndex, setVisibleIndex] = useState(-1); // Tracks the last visible icon index
    const [selectedOption, setSelectedOption] = useState(null); // For displaying the correct section
    const [currentContent, setCurrentContent] = useState({
        title: 'Create Your Workspace',
        description: 'Here you can list and describe the features of your workspace. Add content like text, images, or other components as needed.'
    });
    const [cyclingIndex, setCyclingIndex] = useState(0); // For automatic title/description change

    const radius = 100; // Distance from the center to the icons

    const handleSettingsClick = () => {
        setOpenOptions((prev) => !prev);
        if (!openOptions) {
            setVisibleIndex(0); // Start showing options from the first one
        } else {
            setVisibleIndex(-1); // Hide all options
        }
    };

    const handleOptionClick = (index) => {
        setSelectedOption(index); // Update selected option
        // Manually update content dynamically when an option is clicked
        setCurrentContent({
            title: `Create Your ${options[index].title}`,
            description: options[index].description,
        });
        setOpenOptions(false); // Close the options when an option is clicked
        setVisibleIndex(-1); // Reset the visible index
        setCyclingIndex(index); // Update the cycling index to sync with the clicked option
    };

    useEffect(() => {
        let timer;
        if (openOptions && visibleIndex < options.length) {
            timer = setTimeout(() => {
                setVisibleIndex((prev) => prev + 1);
            }, 300); // Adjust delay as needed
        }
        return () => clearTimeout(timer);
    }, [openOptions, visibleIndex]);

    // Automatic cycling of title and description every 2 seconds
    useEffect(() => {
        const cycleTimer = setInterval(() => {
            setCyclingIndex((prevIndex) => (prevIndex + 1) % options.length); // Loop through the options
        }, 2000); // Change every 2 seconds

        return () => clearInterval(cycleTimer); // Clear the interval on component unmount
    }, []);

    useEffect(() => {
        // Update title and description based on the current cycling index
        setCurrentContent({
            title: (
                <>
                    Explore Your Own <br />
                    <span style={{ fontWeight: 'bold', fontSize: '3rem' }}>{options[cyclingIndex].title}</span>
                </>
            ),
            description: options[cyclingIndex].description,
        });
    }, [cyclingIndex]);

    return (
        <Container maxWidth="lg" sx={{ marginTop: '2rem' }}>
            <Grid container spacing={2} alignItems="center">
                {/* Left Side - Features of Workspace */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>
                        {currentContent.title}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body1">
                            {currentContent.description}
                        </Typography>
                    </Box>
                </Grid>

                {/* Right Side - Settings Icon and Circular Options */}
                <Grid item xs={12} md={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        height: '300px', // Fixed height for proper alignment
                    }}
                >
                 
                    {/* Settings Icon Button */}
                    <IconButton
                        onClick={handleSettingsClick}
                        sx={{
                            backgroundColor: '#10d915',
                            color: '#fff',
                            borderRadius: '50%',
                            width: 64,
                            height: 64,
                            zIndex: 2, // Ensure it is above circular options
                            '&:hover': {
                                backgroundColor: '#02b706',
                            },
                        }}
                    >
                        <SettingsIcon />
                    </IconButton>

                    {/* Circular Options */}
                    {options.map((option, index) => {
                        const angle = (index / options.length) * 2 * Math.PI; // Calculate angle for each option
                        const x = radius * Math.cos(angle); // Calculate x position
                        const y = radius * Math.sin(angle); // Calculate y position

                        return (
                            <Tooltip key={index} title={option.title} arrow>
                                <IconButton
                                    onClick={() => handleOptionClick(index)} // Handle option click
                                    sx={{
                                        position: 'absolute',
                                        top: `calc(45% + ${y}px)`,
                                        left: `calc(46% + ${x}px)`,
                                        backgroundColor: index <= visibleIndex ? option.color : '#fff',
                                        borderRadius: '50%',
                                        width: 48,
                                        height: 48,
                                        color: 'white',
                                        transition: 'opacity 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
                                        opacity: index <= visibleIndex ? 1 : 0,
                                        transform: index <= visibleIndex ? 'scale(1)' : 'scale(0.8)',
                                        '&:hover': {
                                            backgroundColor: index <= visibleIndex
                                                ? `${option.color}AA` // Slightly transparent on hover
                                                : '#e0e0e0',
                                            transform: 'scale(1.2)',
                                        },
                                        boxShadow: 2,
                                    }}
                                >
                                    {option.icon}
                                </IconButton>
                            </Tooltip>
                        );
                    })}
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ padding: 1 }}>
                {/* Conditionally render each section based on selectedOption */}
                {selectedOption === 0 && (
                    <Grid item xs={12}>
                        <Paper sx={{ padding: 2 }} elevation={5}>
                            <Typography variant="h6" gutterBottom>
                                <NotesIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Notes
                            </Typography>
                            <TextField
                                label="Add a new note"
                                fullWidth
                                multiline
                                rows={3}
                                variant="outlined"
                                sx={{ marginBottom: 2 }}
                            />
                            <Button variant="contained" startIcon={<AddCircleOutlineIcon />} fullWidth>
                                Add Note
                            </Button>
                            <Divider sx={{ marginY: 2 }} />
                            <Typography variant="subtitle1">Saved Notes:</Typography>
                            <List>
                                {/* Example Notes */}
                                <ListItem>Note 1: Meeting on Friday</ListItem>
                                <ListItem>Note 2: Finish the React project</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                )}

                {selectedOption === 1 && (
                    <Grid item xs={12}>
                        <Paper sx={{ padding: 2 }}  elevation={5}>
                            <Typography variant="h6" gutterBottom>
                                <QuestionAnswerIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Questions
                            </Typography>
                            <TextField
                                label="Add a new question"
                                fullWidth
                                multiline
                                rows={2}
                                variant="outlined"
                                sx={{ marginBottom: 2 }}
                            />
                            <Button variant="contained" startIcon={<AddCircleOutlineIcon />} fullWidth>
                                Add Question
                            </Button>
                            <Divider sx={{ marginY: 2 }} />
                            <Typography variant="subtitle1">Saved Questions:</Typography>
                            <List>
                                {/* Example Questions */}
                                <ListItem>Question 1: What is React?</ListItem>
                                <ListItem>Question 2: How does useState work?</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                )}

                {selectedOption === 2 && (
                    <Grid item xs={12}>
                        <Paper sx={{ padding: 2 }} elevation={5}>
                            <Typography variant="h6" gutterBottom>
                                <FileCopyIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> CheatSheet
                            </Typography>
                            <TextField
                                label="Add a new cheat sheet item"
                                fullWidth
                                multiline
                                rows={2}
                                variant="outlined"
                                sx={{ marginBottom: 2 }}
                            />
                            <Button variant="contained" startIcon={<AddCircleOutlineIcon />} fullWidth>
                                Add Cheat Sheet Item
                            </Button>
                            <Divider sx={{ marginY: 2 }} />
                            <Typography variant="subtitle1">Saved Cheat Sheet Items:</Typography>
                            <List>
                                {/* Example CheatSheet */}
                                <ListItem>Shortcut 1: Ctrl + C (Copy)</ListItem>
                                <ListItem>Shortcut 2: Ctrl + V (Paste)</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                )}

                {selectedOption === 3 && (
                    <Grid item xs={12}>
                        <Paper sx={{ padding: 2 }} elevation={5}>
                            <Typography variant="h6" gutterBottom>
                                <UploadFileIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> File Storage
                            </Typography>
                            <Button variant="contained" component="label" fullWidth startIcon={<UploadFileIcon />}>
                                Upload File
                                <input type="file" hidden />
                            </Button>
                            <Divider sx={{ marginY: 2 }} />
                            <Typography variant="subtitle1">Stored Files:</Typography>
                            <List>
                                {/* Example Files */}
                                <ListItem>Document1.pdf</ListItem>
                                <ListItem>Project.zip</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                )}

                {selectedOption === 4 && (
                    <Grid item xs={12}>
                        <Paper sx={{ padding: 2 }} elevation={5}>
                            <Typography variant="h6" gutterBottom>
                                <PlaylistAddCheckIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Todo List
                            </Typography>
                            <TextField
                                label="Add a new todo item"
                                fullWidth
                                variant="outlined"
                                sx={{ marginBottom: 2 }}
                            />
                            <Button variant="contained" startIcon={<AddCircleOutlineIcon />} fullWidth>
                                Add Todo
                            </Button>
                            <Divider sx={{ marginY: 2 }} />
                            <Typography variant="subtitle1">Todo Items:</Typography>
                            <List>
                                {/* Example Todo List */}
                                <ListItem>Todo 1: Finish the report</ListItem>
                                <ListItem>Todo 2: Call the client</ListItem>
                            </List>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default WorkspacePage;
