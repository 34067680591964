import React, { useState, useRef, useEffect } from "react";
import { Box, TextField, Typography, Grid, Button, Stack, Container } from "@mui/material";
import { PlayArrow, Code, Share } from "@mui/icons-material"; // Import icons
import beautify from "js-beautify";

const JavascriptEditor = () => {
    const [jsCode, setJsCode] = useState(`
        // Sample JavaScript code
        function sayHello() {
            console.log("Hello, World!");
        }
        sayHello();
    `);
    const [consoleOutput, setConsoleOutput] = useState([]);
    const iframeRef = useRef(null);

    useEffect(() => {
        const iframeWindow = iframeRef.current?.contentWindow;
        if (iframeWindow) {
            iframeWindow.console.log = (...args) => {
                setConsoleOutput((prev) => [...prev, args.join(" ")]);
            };
        }
    }, [iframeRef]);

    const handleRun = () => {
        setConsoleOutput([]); // Clear the previous console output
        if (iframeRef.current) {
            const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
            iframeDocument.open();
            iframeDocument.write(`
                <html>
                    <body>
                        <script type="text/javascript">
                            (function() {
                                var consoleLog = console.log;
                                console.log = function() {
                                    window.parent.console.log.apply(window.parent.console, arguments);
                                    consoleLog.apply(console, arguments);
                                };
                                ${jsCode}
                            })();
                        </script>
                    </body>
                </html>
            `);
            iframeDocument.close();
        }
    };

    const handleBeautify = () => {
        const beautifiedCode = beautify.js(jsCode, { indent_size: 2 });
        setJsCode(beautifiedCode);
    };

    const handleShare = () => {
        navigator.clipboard
            .writeText(jsCode)
            .then(() => alert("Code copied to clipboard!"))
            .catch((err) => alert("Failed to copy code: " + err.message));
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: '3em' }}>
            <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                {/* Run Button */}
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleRun}
                    startIcon={<PlayArrow />}
                    sx={{ textTransform: "none", color: "white" }}
                >
                    Run
                </Button>

                {/* Beautify Button */}
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleBeautify}
                    startIcon={<Code />}
                    sx={{ textTransform: "none" }}
                >
                    Beautify
                </Button>

                {/* Share Button */}
                <Button
                    variant="contained"
                    color="warning"
                    onClick={handleShare}
                    startIcon={<Share />}
                    sx={{ textTransform: "none", color: "white" }}
                >
                    Share
                </Button>
            </Stack>
            <Grid container spacing={2} sx={{ height: "100%" }}>
                {/* Code Input Area */}
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            height: "93%",
                            padding: 2,
                            bgcolor: "#282c34",
                            color: "#fff",
                            borderRadius: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h6" sx={{ color: "lightgray", marginBottom: 1 }}>
                            JavaScript Code
                        </Typography>
                        <TextField
                            value={jsCode}
                            onChange={(e) => setJsCode(e.target.value)}
                            variant="outlined"
                            multiline
                            rows={20}
                            fullWidth
                            sx={{
                                backgroundColor: "#1e1e1e",
                                color: "#fff",
                                flex: 1,
                                "& .MuiInputBase-input": { color: "white" },
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#4d4d4d",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#fff",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#fff",
                                    },
                                },
                            }}
                        />
                    </Box>
                </Grid>

                {/* Rendered JavaScript Area */}
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            height: "93%",
                            padding: 2,
                            backgroundColor: "#f5f5f5",
                            borderRadius: 2,
                            overflowY: "hidden",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h6" sx={{ marginBottom: 1 }}>
                            Console Output
                        </Typography>
                        <Box
                            sx={{
                                backgroundColor: "#000",
                                color: "#0f0",
                                padding: "10px",
                                height: "90%",
                                overflowY: "auto",
                                borderRadius: "4px",
                            }}
                        >
                            {consoleOutput.map((line, index) => (
                                <Typography key={index} variant="body2">
                                    {line}
                                </Typography>
                            ))}
                        </Box>
                        <iframe
                            ref={iframeRef}
                            title="Rendered JavaScript"
                            style={{
                                display: "none", // Hide the iframe since we are using it only to run JS
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default JavascriptEditor;
