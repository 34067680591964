import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Link, IconButton, Box, Skeleton, Slide } from '@mui/material';
import { Facebook, Instagram, YouTube, LinkedIn } from '@mui/icons-material';
import { styled } from '@mui/system';

const FooterContainer = styled(Container)(({ theme }) => ({
    backgroundColor: 'white',
    marginTop: '35px',
    padding: '40px 20px',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.6)',
    overflow: 'visible',
}));

const SocialIcon = styled(IconButton)(({ theme }) => ({
    color: 'linear-gradient(to right, orange, green)',
    '& svg': {
        fontSize: '2.2rem',
    },
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}));

const FooterLink = styled(Link)(({ theme }) => ({
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

const Divider = styled('div')(({ theme }) => ({
    height: '4px',
    background: 'linear-gradient(to right, #9F63FF, #10d915)',
    borderRadius: 2,
    margin: '8px 0',
    width: '85%',
}));

const CategoryHeader = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .seeAll': {
        marginLeft: 'auto',
    },
}));

const Footer = () => {
    const [showFooter, setShowFooter] = useState(false);
    const loading = false; // Change this to true to simulate loading

    useEffect(() => {
        setShowFooter(true);
    }, []);

    return (
        <Slide direction="up" in={showFooter} timeout={1000}>
            <FooterContainer maxWidth={false}>
                <Container maxWidth="lg">
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h5" noWrap style={{ fontWeight: 'bold', color: '#9F63FF' }}>
                                Knowledge
                                <span style={{ color: '#10d915' }}>Lift</span>
                            </Typography>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Learn, Practice, Succeed.</Typography>
                            <Box mt={3}>
                                <SocialIcon href="#"><Facebook /></SocialIcon>
                                <SocialIcon href="#"><Instagram /></SocialIcon>
                                <SocialIcon href="#"><YouTube /></SocialIcon>
                                <SocialIcon href="#"><LinkedIn /></SocialIcon>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CategoryHeader variant="h6" gutterBottom>
                                Tutorial
                                <Typography variant="body1" className="seeAll">See All</Typography>
                            </CategoryHeader>
                            <Divider style={{ width: '100%' }} />
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Java</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Php</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Angular</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">MangoDB</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Laravel</Typography>}
                                </Grid>
                                <Grid item xs={6}>
                                    {loading ? <Skeleton variant="text" width ="100%" /> : <Typography variant="body1">Python</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">ReactJs</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">SpringBoot</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Django</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">NodeJs</Typography>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" gutterBottom>Contact Us</Typography>
                            <Divider />
                            <Box mb={1}>
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">https://www.knowledgelift.in</Typography>}
                            </Box>
                            <Box mb={1}>
                                {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">+91 7008392889, 7381883483</Typography>}
                            </Box>
                            {loading ? <Skeleton variant="text" width="100%" /> : <FooterLink style={{ fontWeight: 'bold' }} href="mailto:amomarket@gmail.com">knowledgelift@gmail.com</FooterLink>}
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Typography variant="h6" gutterBottom>Useful Links</Typography>
                            <Divider />
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">About</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Careers</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Blog</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Teacher</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Developer</Typography>}
                                </Grid>
                                <Grid item xs={6}>
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Privacy</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Terms</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">FAQs</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Security</Typography>}
                                    {loading ? <Skeleton variant="text" width="100%" /> : <Typography variant="body1">Partner</Typography>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </FooterContainer>
        </Slide>
    );
};

export default Footer;