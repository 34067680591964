import React, { useState } from 'react';
import { Grid, Typography, Container, Paper, Avatar} from '@mui/material';

const levels = [
  { image: 'image/portal/education/kg.png', title: 'KG', description: 'Fun and engaging content for kids to learn basics of programming and technology.' },
  { image: 'image/portal/education/school.png', title: 'School', description: 'Interactive tutorials for school-level students to learn programming fundamentals.' },
  { image: 'image/portal/education/college.png', title: 'Intermediate', description: 'Content designed for intermediate learners focusing on deeper programming concepts.' },
  { image: 'image/portal/education/graduate.png', title: 'Graduate', description: 'Advanced tutorials aimed at preparing graduate students for real-world development tasks.' },
  { image: 'image/portal/education/postgraduate.png', title: 'Postgraduate', description: 'tutorials for postgraduate students exploring complex, cutting-edge technologies.' },
  { image: 'image/portal/education/higher_degree.png', title: 'Higher Degree', description: 'Advanced tutorials for higher-degree students on complex technologies.' },
];

const ClassWiseTutorialPage = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (   
      <Container maxWidth="lg" style={{ marginTop: '3rem' }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              We Provide Tutorials Segmented by Levels
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Whether you're a beginner or an advanced learner, we have tutorials tailored to your level of expertise.
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}> {/* On small screens, this takes full width */}
            <Grid container spacing={2}>
              {levels.map((level, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Paper
                    elevation={5}
                    style={{
                      padding: '0.5rem', // Reduced padding for smaller size
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '0.5rem', // Reduced gap between elements
                      cursor: 'pointer',
                      textAlign: 'center',
                      maxWidth: '270px', // Adjusted max width
                      maxHeight: '200px', // Adjusted max height
                      margin: 'auto', // Centering Paper
                    }}
                  >
                    <Avatar
                      src={level.image}
                      alt={level.title}
                      style={{
                        width: 50, // Decreased Avatar width
                        height: 50, // Decreased Avatar height
                        borderRadius: 2,
                      }}
                    />
                    <Typography variant="h6" fontWeight="bold" color="primary">
                      {level.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {level.description}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} display="flex" flexDirection="column" alignItems="center"> {/* On small screens, this takes full width */}
            <img
              src={'image/portal/education.png'}
              alt="Tutorials Segmented"
              className={imageLoaded ? 'fade-in' : ''}
              style={{ maxWidth: '100%', height: 'auto', marginBottom: '0.5rem' }} // Add margin for spacing
              onLoad={handleImageLoad}
            />
            <Typography variant="h6" fontWeight="bold" align="center">
              Comprehensive Section-wise Tutorials
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '0.5rem' }}>
              We We provide tutorials segmented by levels from KG-1 to postgraduate studies.
            </Typography>
          </Grid>
        </Grid>
      </Container>
  );
};

export default ClassWiseTutorialPage;
