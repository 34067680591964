import React, { useEffect, useState } from 'react';
import { AppBar as MuiAppBar, Toolbar, IconButton, InputBase, Box, Slide, Avatar, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  border: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
  marginLeft: 0,
  width: '100%',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.1),
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
}));

const AppBar = () => {
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    setShowHeader(true);
  }, []);

  return (
    <Slide direction="down" in={showHeader} timeout={1000}>
      <MuiAppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{
          zIndex: 1201,
          boxShadow: '0 6px 10px rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(15px)',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', px: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h5"
              noWrap
              style={{ fontWeight: "bold", color: "#9F63FF" }}
            >
              Knowledge
              <span style={{ color: "#2eee07" }}>Lift</span>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '400px', width: '100%', ml: { sm: 2, md: '110px' } }}>
            <Search
              sx={{
                backgroundColor: 'white',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                  transform: 'scale(1.02)',
                },
                '&:focus-within': {
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                  transform: 'scale(1.02)',
                },
              }}
            >
              <SearchIconWrapper sx={{ color: '#5b5858' }}>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase placeholder="Search" inputProps={{ 'aria-label': 'search' }} />
            </Search>
          </Box>

          <IconButton color="inherit">
            <Avatar
              alt="Avatar"
              src="/image/portal/avatar.png" // Replace with your avatar image path
              sx={{
                width: 40,
                height: 40,
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              }}
            />
          </IconButton>
        </Toolbar>
      </MuiAppBar>
    </Slide>
  );
};

export default AppBar;
