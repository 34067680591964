import React, { useState } from "react";
import { Box, TextField, Typography, Grid, Button, Stack, Container } from "@mui/material";
import { PlayArrow, Code, Share } from "@mui/icons-material";
import beautify from "js-beautify";
import axios from "axios";

const PythonCompiler = () => {
    const [pythonCode, setPythonCode] = useState(`# Write your Python code here\nprint("Hello, World!")`);
    const [output, setOutput] = useState("");

    const handleRun = () => {
        // Prepare the data for the Codex API call
        const data = {
            code: pythonCode,
            language: "py", // Specify Python as the language
            input: "", // Input can be provided if necessary
        };

        axios({
            method: "POST",
            url: "https://api.codex.jaagrav.in", // Codex API URL
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: new URLSearchParams(data),
        })
            .then((response) => {
                // Handle the API response and set the output
                const { output, error } = response.data;
                setOutput(output || `Error: ${error}`);
            })
            .catch((error) => {
                setOutput(`Error: ${error.message}`);
            });
    };

    const handleBeautify = () => {
        // Beautify Python code (optional)
        const beautifiedCode = beautify.js(pythonCode, { indent_size: 2 });
        setPythonCode(beautifiedCode);
    };

    const handleShare = () => {
        // Copy the code to clipboard
        navigator.clipboard
            .writeText(pythonCode)
            .then(() => alert("Code copied to clipboard!"))
            .catch((err) => alert("Failed to copy code: " + err.message));
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: '3em' }}>
            <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                {/* Run Button */}
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleRun}
                    startIcon={<PlayArrow />}
                    sx={{ textTransform: "none", color: "white" }}
                >
                    Run
                </Button>

                {/* Beautify Button */}
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleBeautify}
                    startIcon={<Code />}
                    sx={{ textTransform: "none" }}
                >
                    Beautify
                </Button>

                {/* Share Button */}
                <Button
                    variant="contained"
                    color="warning"
                    onClick={handleShare}
                    startIcon={<Share />}
                    sx={{ textTransform: "none", color: "white" }}
                >
                    Share
                </Button>
            </Stack>

            <Grid container spacing={2} sx={{ height: "100%" }}>
                {/* Code Input Area */}
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            height: "93%",
                            padding: 2,
                            bgcolor: "#282c34",
                            color: "#fff",
                            borderRadius: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h6" sx={{ color: "lightgray", marginBottom: 1 }}>
                            Python Code
                        </Typography>
                        <TextField
                            value={pythonCode}
                            onChange={(e) => setPythonCode(e.target.value)}
                            variant="outlined"
                            multiline
                            rows={20}
                            fullWidth
                            sx={{
                                backgroundColor: "#1e1e1e",
                                color: "#fff",
                                flex: 1,
                                "& .MuiInputBase-input": { color: "white" },
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#4d4d4d",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#fff",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#fff",
                                    },
                                },
                            }}
                        />
                    </Box>
                </Grid>

                {/* Console Output Area */}
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            height: "93%",
                            padding: 2,
                            backgroundColor: "#f5f5f5",
                            borderRadius: 2,
                            overflowY: "hidden",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h6" sx={{ marginBottom: 1 }}>
                            Console Output
                        </Typography>
                        <Box
                            sx={{
                                height: "100%",
                                padding: 1,
                                bgcolor: "#000",
                                color: "#fff",
                                borderRadius: 2,
                                overflowY: "auto",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {output}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PythonCompiler;
