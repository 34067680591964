import React, { useState, useRef } from "react";
import { Box, TextField, Typography, Grid, Button, Stack, Container, } from "@mui/material";
import { PlayArrow, Code, Share } from "@mui/icons-material"; // Import icons
import beautify from "js-beautify";

const HtmlEditor = () => {
    const [htmlCode, setHtmlCode] = useState(`<!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Document</title>
        </head>
        <body>
          <h1>Hello, World!</h1>
          <p>welcome to KnowledgeLift.</p>
        </body>
        </html>`);
    const iframeRef = useRef(null);

    const handleRun = () => {
        if (iframeRef.current) {
            const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
            iframeDocument.open();
            iframeDocument.write(htmlCode);
            iframeDocument.close();
        }
    };

    const handleBeautify = () => {
        const beautifiedCode = beautify.html(htmlCode, { indent_size: 2 });
        setHtmlCode(beautifiedCode);
    };

    const handleShare = () => {
        navigator.clipboard
            .writeText(htmlCode)
            .then(() => alert("Code copied to clipboard!"))
            .catch((err) => alert("Failed to copy code: " + err.message));
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: '3em' }}>
            <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                {/* Run Button */}
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleRun}
                    startIcon={<PlayArrow />} // Add icon here
                    sx={{ textTransform: "none", color: "white" }}
                >
                    Run
                </Button>

                {/* Beautify Button */}
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleBeautify}
                    startIcon={<Code />} // Add icon here
                    sx={{ textTransform: "none" }}
                >
                    Beautify
                </Button>

                {/* Share Button */}
                <Button
                    variant="contained"
                    color="warning"
                    onClick={handleShare}
                    startIcon={<Share />} // Add icon here
                    sx={{ textTransform: "none", color: "white" }}
                >
                    Share
                </Button>
            </Stack>
            <Grid container spacing={2} sx={{ height: "100%" }}>
                    {/* Code Input Area */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                height: "93%",
                                padding: 2,
                                bgcolor: "#282c34",
                                color: "#fff",
                                borderRadius: 2,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="h6" sx={{ color: "lightgray", marginBottom: 1 }}>
                                HTML Code
                            </Typography>
                            <TextField
                                value={htmlCode}
                                onChange={(e) => setHtmlCode(e.target.value)}
                                variant="outlined"
                                multiline
                                rows={20}
                                fullWidth
                                sx={{
                                    backgroundColor: "#1e1e1e",
                                    color: "#fff",
                                    flex: 1,
                                    "& .MuiInputBase-input": { color: "white" },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#4d4d4d",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#fff",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#fff",
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Grid>

                    {/* Rendered HTML Area */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                height: "93%",
                                padding: 2,
                                backgroundColor: "#f5f5f5",
                                borderRadius: 2,
                                overflowY: "hidden",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                Result
                            </Typography>
                            <iframe
                                ref={iframeRef}
                                title="Rendered HTML"
                                style={{
                                    border: "none",
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "#fff",
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
        </Container>
    );
};

export default HtmlEditor;
