import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Button, Container,  } from '@mui/material';
import { Typewriter } from 'react-simple-typewriter';

const ShowFeaturePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [contentData, setContentData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Fetch data from the API
    const fetchContentData = async () => {
      try {
        const response = await fetch(`${apiUrl}/portal/landing-content`);
        const result = await response.json();
        if (result.status) {
          setContentData(result.data);
        }
      } catch (error) {
        console.error("Error fetching landing content:", error);
      }
    };
    fetchContentData();
  }, [apiUrl]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentData.length);
    }, 9000);
    return () => clearInterval(timer);
  }, [contentData.length]);

  const currentContent = contentData[currentIndex];

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
      <Container maxWidth="lg" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
        {currentContent && (
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} style={{ minHeight: '250px' }}> {/* Adjust the minHeight value as needed */}
              <Typography variant="h3" component="h1" fontWeight="bold" gutterBottom
                style={{ minHeight: '100px' }} // Set a fixed height based on the max expected height
              >
                <Typewriter
                  key={currentIndex} // Trigger re-render on index change
                  words={[currentContent.title]}
                  loop={false}
                  cursor
                  cursorStyle="|"
                  typeSpeed={150}
                  deleteSpeed={35}
                  delaySpeed={2000}
                />
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: currentContent.description }}
                style={{ minHeight: '100px' }} // Set a fixed height for the description
              >
              </Typography>

              <Typography variant="h6" component="p" fontWeight="bold" style={{ marginTop: '1rem' }}>
                Stay Updated with the Latest tutorials and updates
              </Typography>

              <Grid container spacing={1} alignItems="center" style={{ marginTop: '0.5rem' }}>
                <Grid item xs={8} sm={9}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Enter your email address"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Button fullWidth variant="contained" color="primary">
                    Subscribe
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <img
                src={currentContent.image}
                alt="Programming illustration"
                className={imageLoaded ? 'fade-in' : ''}
                style={{ maxWidth: '100%', height: 'auto' }}
                onLoad={handleImageLoad}
              />
            </Grid>
          </Grid>
        )}
      </Container>
  );
};

export default ShowFeaturePage;
