import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Container, Paper, Avatar } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

const InterviewQuestionPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [questions, setQuestions] = useState([]);
  const [fetchAll, setFetchAll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from the API based on fetchAll state
    fetch(`${apiUrl}/portal/interview-question${fetchAll ? '?all_data=true' : ''}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setQuestions(fetchAll ? data.data : data.data.slice(0, 11));  // Show all or limit to 11 items
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [apiUrl, fetchAll]);  // Fetch data again when fetchAll state changes

  const handleToggleFetchAll = () => {
    setFetchAll(!fetchAll);  // Toggle fetchAll state
  };

  // Handle Paper click to navigate to the specific subject page
  const handlePaperClick = (id) => {
    navigate(`/portal/interview-question/${id}`); // Navigate to the subject page with the specific id
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '3rem' }}>
      {/* Section Title */}
      <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
        Interview Questions to Prepare You for Success
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" textAlign="center" gutterBottom>
        Browse through our collection of interview questions for the most in-demand technologies.
      </Typography>

      {/* Grid Layout for Interview Questions */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="center">
            {questions.map((item, index) => (
              <Grid item xs={6} sm={4} md={2} key={index}> {/* Adjust xs, sm, md for 6-7 per row */}
                <Paper
                  elevation={10}
                  style={{
                    padding: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    width: 'calc(100% - 30px)',
                    cursor: 'pointer',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease', 
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'translateY(-5px)'; 
                    e.currentTarget.style.boxShadow = '0px 6px 12px rgba(0, 0, 0, 0.3)'; 
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'translateY(0)';
                    e.currentTarget.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)'; 
                  }}
                  onClick={() => handlePaperClick(item.id)}
                >
                  <Avatar src={item.image} alt={item.name} style={{ width: 45, height: 45, borderRadius: 1 }} />
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="primary"
                    noWrap
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Paper>
              </Grid>
            ))}

            {/* View All Questions Button */}
            <Grid item xs={6} sm={4} md={2}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleToggleFetchAll}
                style={{
                  height: '100%',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Customize the shadow here
                  transition: 'box-shadow 0.4s ease', // Optional transition for smooth effect
                }}
              >
                <Avatar
                  alt="Tutorial Icon"
                  src="image/portal/interview/faq.gif" // Replace with your image URL
                  style={{ marginRight: '1rem', width: 45, height: 45 }} // Add margin to space out the text
                />
                {fetchAll ? 'Show less' : 'View all'}
                {fetchAll ? <ArrowBackIcon /> : <ArrowForwardIcon />}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InterviewQuestionPage;
