import React, { useState, useEffect } from 'react';
import AppBar from './AppBar';
import Footer from './Footer';
import Theme from './Theme';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Layout = ({ children }) => {
    const [showScroll, setShowScroll] = useState(false);

    // Ensure the page is scrolled to the top when it loads
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    }, []);

    // Handle scroll visibility
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setShowScroll(true);
            } else {
                setShowScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <ThemeProvider theme={Theme}>
            <AppBar />
            <Box component="main" sx={{ pt: 8, pb: 2 }}>
                {children}
            </Box>
            <Footer />
            
            {/* Scroll to Top Button with Tooltip */}
            {showScroll && (
                <Tooltip title="Scroll To Top" arrow>
                    <IconButton
                        onClick={scrollToTop}
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                            backgroundColor: 'primary.main',
                            color: 'white',
                            '&:hover': { backgroundColor: 'primary.dark' },
                            width: 48,
                            height: 48,
                            boxShadow: 5,
                        }}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                </Tooltip>
            )}
        </ThemeProvider>
    );
};

export default Layout;
